import { graphql } from "gatsby";
import contentParser from "gatsby-wpgraphql-inline-images";
import React from "react";
import CaseStudies from "../components/CaseStudies/listing";
import OurClients from "../components/Clients";
import WorkWithUs from "../components/Forms/workwithus";
import SEO from "../components/SEO";
import Layout from "../layouts/home";
import { inlineUrls } from "../utils";




class Index extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            title,
            excerpt,
            content,
            acf_vimeo_video: { video },
            acf_our_clients: { clients },
          },
        },
      },
    } = this.props;

    // console.log(clients);

    return (
      <Layout location={location} cssClass="home">
        <SEO title={title} description={excerpt} />
        <section id="header" className="bg-light-gray text-center">
          <div className="video-wrapper">
            <iframe
              title="balcony8"
              src={`https://player.vimeo.com/video/${video}?background=1&autoplay=1&loop=1&byline=0&title=0&mute=1`}
              frameBorder="0"
            ></iframe>
          </div>
          <a className="scroll-btn" href="#intro">
            Scroll down
          </a>
        </section>
        <section id="intro" className="bg-charcoal p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {contentParser({ content: excerpt }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <CaseStudies />
        <OurClients data={clients} />
        <WorkWithUs />
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  {
    wordPress {
      pageBy(pageId: 3) {
        pageId
        title
        excerpt
        content
        acf_vimeo_video {
          video
        }
        acf_our_clients {
          ...OurClients
        }
      }
    }
  }
`;
