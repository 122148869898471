import React, { Component } from "react";
import { graphql } from "gatsby";
import slugify from "slugify";

export default class OurClients extends Component {
  render() {
    const { data: clients } = this.props;

    // console.log(clients);

    return (
      <section className="p-top p-bottom" id="clients">
        <div className="container-full">
          <div className="row">
            <div className="col-md-12 pb-0 pt-0">
              <h2 className="text-center text-uppercase mt-0">Our Clients</h2>
            </div>
            {clients &&
              clients.map((item, index) => {
                // console.log(item.logo.imageFile.publicURL);
                return (
                  <div className="client-logo" key={index}>
                    {item && item.logo && (
                      <img
                        src={item.logo.imageFile.publicURL}
                        className={slugify(item.client.toLowerCase())}
                        alt={item.client}
                      ></img>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }
}

export const fragment = graphql`
  fragment OurClients on WordPress_Page_AcfOurClients {
    clients {
      fieldGroupName
      client
      logo {
        altText
        sourceUrl
        srcSet
        mediaItemId
        modified
        imageFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
